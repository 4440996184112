import { pageUrl } from '~/helpers/urls'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    lang: {
      code: 'ru',
      id: null,
    },
    lang_tree: []
  }),
  getters: {
    lang_id: (state) => state.lang.id
  },
  actions: {
    setLang(lang_code) {
      let langItem = this.lang_tree.find(item => item.meta.locale === lang_code)
      this.lang.code = langItem.meta.locale
      this.lang.id = langItem.id
    },
    async getLangTree() {
      if (this.lang.id !== null) return
      const params = {
        type: 'home.HomePage',
        fields: 'language_code',
      }
      try {
        const res = await useApiFetch(pageUrl, {params})
        this.lang_tree = res.items
        this.setLang('ru') //this.$i18n.defaultLocale
      } catch (error) {
        return error
      }
    },
  }
})
